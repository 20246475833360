import styled from "@emotion/styled"
import React from "react"
import { device } from "./layout/GlobalStyles"

const StyledImageContainer = styled.section`
  /* regular ipad */
  img {
    height: 50vh;
    margin-top: 0px;
  }
  @media ${device.tablet} {
    img {
      margin-top: 0px;
      height: 75vh;
    }
  }
  @media ${device.laptop} {
    img {
      max-height: 612px;
      width: 100vw;
      height: 100vh;
    }
  }
  @media ${device.desktop} {
    img {
      max-height: 613px;
      height: 100vh;
    }
  }
  @media ${device.desktop} {
    img {
      max-height: 713px;
      height: 100vh;
    }
  }
  @media only screen and (min-width: 2000px) {
    img {
      max-height: 1013px;
      height: 100%;
    }
  }
  /* @media only screen and (min-width: 2500px) {
    img {
      
      height: 100vh;
    }
  } */
`

export const ImageContainer = ({ children, reverse }) => {
  return <StyledImageContainer>{children}</StyledImageContainer>
}
