import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "../layout/GlobalStyles"

const StyledHeroContainer = styled.div`
  padding: 54px 0px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    color: #16675a;
    font-family: ${fonts.secondary};
    font-size: 48px;
    font-weight: 800;
    margin: 0em;

    letter-spacing: -2.5px;
    line-height: 65px;
  }
  p {
    color: ${colors.dark3};
    font-size: 30px;
    font-weight: 900;
    margin: 0 0px 50px 0px;
    letter-spacing: -0.94px;
    line-height: 36px;
  }

  img {
    width: 320px;
  }
  @media ${device.tablet} {
    flex-direction: row;
    h1 {
      font-size: 60px;
      margin: 0em;
      letter-spacing: -2.5px;
      line-height: 85px;
    }
  }
  @media ${device.laptop} {
    justify-content: space-between;
    padding: 148px 0px 138px;
    max-width: 942px;
    h1 {
      max-width: 380px;
      font-size: 60px;
      letter-spacing: -1.88px;
      line-height: 65px;
      margin-bottom: 10px;
    }
    .logo {
      position: relative;
      bottom: 10px;
    }
    img {
      width: 463.63px;
    }
  }
  @media ${device.tablet} {
    justify-content: space-between;
    max-width: 712px;
  }
  @media ${device.laptop} {
    justify-content: space-between;
    max-width: 1012px;
  }
  @media ${device.laptopL} {
    max-width: 1112px;

    h1 {
      max-width: 380px;
      font-size: 80px;
      line-height: 85px;
    }
    img {
      width: 563.63px;
    }
  }
`

export const HeroContainer = ({ children }) => {
  return (
    <StyledHeroContainer className="container">{children}</StyledHeroContainer>
  )
}
