import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./Panel-Container"
import { Button } from "../layout/StyledComponents"
import { Fancybox } from "../Fancy-Box"

const MainContainer = styled.div`
  padding: 72px 0px;
  background: ${colors.primary1};
  .image {
    margin-bottom: 25px;
  }
  h4 {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: -1.56px;
    line-height: 48px;
    margin: 0;
  }
  p {
    letter-spacing: -0.69px;
    line-height: 36px;
    font-size: 22px;
  }
  @media ${device.tablet} {
    .video-panel {
      img {
        transition: 0.2s ease-in-out;
      }
    }
    .video-panel:hover {
      img {
        transform: scale(0.99);
      }
    }
    img {
      width: 700px;
    }
  }
  @media ${device.laptop} {
    .image {
      position: relative;
      right: 000px;
    }
    padding: 128px 0px 128px;
    img {
      height: 278px;
      width: 451.47px;
    }
    .text-container {
      h4 {
        margin-bottom: 25px;
      }
      width: 503px;
    }
  }
`

export const RapidPanels = () => {
  return (
    <MainContainer>
      <PanelContainer>
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://vimeo.com/716495326"
        > */}
        <Fancybox options={{ infinite: false }}>
          <button
            style={{ background: "none", border: "none" }}
            data-fancybox="gallery"
            data-src="https://vimeo.com/716495326"
            color="yellow"
          >
            <StaticImage
              className="video-panel image"
              src="../../images/programs/rapid/child-looking.png"
              placeholder="none"
              quality={100}
              alt="child looking on"
            />
          </button>
        </Fancybox>
        {/* </a> */}
        <div className="text-container">
          <p>
            Instituted in 2020 to respond to the emerging COVID-19 pandemic in
            Kenya, Relief Aid for People in Despair (RAPID) is MLIFE's flagship
            initiative providing unconditional small grants to families in need
            in Kenya.
          </p>

          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vimeo.com/716495326"
          > */}
          <Fancybox options={{ infinite: false }}>
            <Button
              data-fancybox="gallery"
              data-src="https://vimeo.com/716495326"
              color="yellow"
            >
              Watch & learn more
            </Button>
          </Fancybox>
          {/* </a> */}
        </div>
      </PanelContainer>
    </MainContainer>
  )
}
