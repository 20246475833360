import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"
import { Link } from "gatsby"

const MainContainer = styled.div`
  padding: 72px 0px 32px;
  background: ${colors.primary2};
  color: ${colors.white};
  p {
    color: ${colors.white};
  }
  h4 {
    margin: 0;
    padding-top: 32px;
    color: ${colors.white};
    font-size: 50px;
    font-weight: 800;
    letter-spacing: -1.56px;
    line-height: 55px;
  }
  li {
    width: 222px;
    font-size: 22px;
    font-family: ${fonts.primary};
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.laptop} {
    h4 {
      padding-top: 0px;
      margin-top: 15px;
    }
    .flex-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  @media ${device.laptop} {
    padding: 68px 0px 68px;
    .general-paragraph {
      min-width: 635px;
      span {
        font-weight: 900;
      }
    }
    .long-paragraph {
      min-width: 600px;
      margin-top: 20px;
    }
  }
`

const TextContainer = styled.div`
  p {
    font-size: 22px;
    letter-spacing: -0.69px;
    line-height: 36px;
    font-size: 22px;
    max-width: 340px;
  }
  padding-bottom: 54px;

  border-bottom: 1px solid rgba(227, 191, 42, 1);
  @media ${device.tablet} {
    .button-container {
      padding-top: 54px;

      p {
        max-width: 651px;
      }
      p {
        font-size: 22px;
        letter-spacing: -0.69px;
        line-height: 36px;
      }
    }
  }

  @media ${device.tablet} {
    .button-container {
      display: flex;
      align-items: center;
      button {
        margin-left: 50px;
      }
    }
  }

  @media ${device.laptop} {
    padding-top: 38px;
    .button-container {
      margin-left: 0px;
      margin-right: 10px;
      p {
        bottom: 0px;
      }
      button {
        margin-left: 40px;
      }
    }
  }
`

const CovidContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 22px;
  }
  @media ${device.laptop} {
    padding: 67px 0px 0px;
  }
`

const CovidLeftColumn = styled.div`
  margin-bottom: 72px;
  h4 {
    margin: 0;
  }
  p {
    width: 222px;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    h4 {
      margin-top: 10px;
      width: 300px;
    }
    margin-bottom: 0px;
  }
`

const CovidRightColumn = styled.div`
  @media ${device.tablet} {
    max-width: 721px;
    .number-container {
      margin-top: 20px;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
  }
`

const NumberPanel = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: start;
  padding: 0;

  span {
    font-family: ${fonts.secondary};
    font-size: 28px;
    font-weight: 700;
    padding: 2px 15px;
    min-width: 18px;
    display: flex;
    justify-content: center;
    border: 4px solid rgba(227, 191, 42, 0.4);
    border-radius: 200px;
  }
  p {
    margin: 0px 0px 20px 35px;
    width: 228px;
  }
  @media ${device.tablet} {
    p {
      width: 565px;
    }
  }
`

// const ButtonContainer = styled.div`

// `

export const CrisisInKenya = () => {
  return (
    <MainContainer>
      <TextContainer className="flex-container container">
        <h4>
          The crisis
          <br /> in Kenya
        </h4>
        <p className="general-paragraph">
          While the global COVID-19 pandemic had devastating effects on every
          country, Kenyans were particularly hard hit economically due to
          insufficient government funding and a history of colonialism and
          disinvestment.
        </p>
      </TextContainer>
      <TextContainer className="flex-container container">
        <h4>
          Our <br />
          response
        </h4>
        <p className="general-paragraph">
          When the pandemic hit, the Elder Board canceled MLIFE's programming
          through October 2021 across our three campuses: San Francisco,
          Nairobi, and Kilifi, and provided pay to all employees through June
          2020.{" "}
        </p>
      </TextContainer>
      <TextContainer className="flex-container container">
        <h4>
          Our <br />
          impact
        </h4>
        <div>
          <p className="general-paragraph">
            <span>
              We provided fast, unconditional financial assistance to needy
              families.
            </span>{" "}
            MLIFE provided direct cash support and water to Kenya's hardest-hit
            communities by creating the Relief Aid for People in Despair (RAPID)
            program. RAPID provided clean water to 400 families for six weeks,
            financial grants to four primary schools, and supported 40 families
            with a quick-response direct cash transfer program.
          </p>
          <p className="general-paragraph">
            <span>
              We instituted the first fully funded Tech Center in an
              under-resourced school in Kenya
            </span>{" "}
            Kawangware Primary School was one of the schools devastated by
            COVID-19 because the school lacked the infrastructure to support
            distance learning. Through the generous support of the Rugged
            Elegance Foundation and other partners, MLIFE Foundation funded the
            creation of the LEAP Tech Center at Kawangware Primary School to
            activate learning during COVID-19 and build a model that supports
            24/7 education that can be replicated throughout Kenya during
            COVID-19 and beyond.
          </p>
          <p className="long-paragraph">
            Currently, LEAP Tech Center serves 500+ students.{" "}
          </p>
        </div>
      </TextContainer>
      <TextContainer className="flex-container container">
        <h4>
          Living <br />
          with <br />
          COVID-19
        </h4>
        <p className="general-paragraph">
          For many Americans and citizens of the western world, life is
          returning to normal. The latest data from Kenya's Ministry of Health
          shows that only 2 percent of the entire population is vaccinated. The
          economic and health toll of COVID-19 has just started.
        </p>
      </TextContainer>
      <CovidContainer className="container">
        <div className="flex-container">
          <CovidLeftColumn>
            <h4>Our plan to lessen the impact of COVID-19</h4>
          </CovidLeftColumn>
          <CovidRightColumn>
            <div className="number-container">
              <NumberPanel>
                <span>1</span>
                <p>
                  Support global efforts to increase mass testing and
                  vaccination for the entire population.{" "}
                </p>
              </NumberPanel>
              <NumberPanel>
                <span>2</span>
                <p>
                  Institute a funding facility to provide unconditional one-time
                  grants of $250 to $750 to small traders to purchase new
                  inventory or learn a new skill.
                </p>
              </NumberPanel>
              <NumberPanel>
                <span>3</span>
                <p>
                  Institute additional tech centers across our programs; LEAP,
                  MLOVE, Ecclesia, and Spaces of Grace.
                </p>
              </NumberPanel>
            </div>
          </CovidRightColumn>
        </div>
      </CovidContainer>
      <TextContainer
        style={{
          marginTop: "20px",
          paddingTop: "00px",
          borderTop: "1px solid rgba(227, 191, 42, 1)",
          borderBottom: "none",
        }}
        className="flex-container container"
      >
        <h4>{""}</h4>
        <div className="button-container">
          <p>Donate today to accelerate our impact!</p>
          <Link to="/donate/">
            <Button color="yellow">Donate</Button>
          </Link>
        </div>
      </TextContainer>
    </MainContainer>
  )
}
