import styled from "@emotion/styled"
import React from "react"
import { device } from "../layout/GlobalStyles"
import rapidHero from "../../images/programs/rapid/various-activities.svg"
import rapidLogo from "../../images/programs/logo-rapid-no-title.svg"
import { HeroContainer } from "./Hero-Container"

const Container = styled.div`
  .rapid-logo {
    width: 65px;
    margin-bottom: 10px;
  }
  @media ${device.laptop} {
    margin-bottom: -34px;
    p {
      margin-top: 15px;
    }

    .hero-image {
      width: 600px;
      position: relative;
      right: 56px;
      img {
        width: 900px;
      }
    }
  }
`

export const RapidHero = () => {
  return (
    <Container>
      <HeroContainer className="container">
        <div>
          <img className="rapid-logo" src={rapidLogo} alt="rapid logo" />
          <h1>RAPID</h1>
          <p>
            Relief Aid for People
            <br /> in Despair
          </p>
        </div>
        <img
          className="hero-image"
          src={`${rapidHero}`}
          alt="animated people doing various activities"
        />
      </HeroContainer>
    </Container>
  )
}
