import React from "react"
import { RapidHero } from "../../components/heros/Rapid-Hero"
import { BecomeOneOfUs } from "../../components/Become-One-Of-Us"
import { RapidPanels } from "../../components/panels/Rapid-Panels"
import { RapidBio } from "../../components/programs-bio/Rapid-Bio"
import { ImageContainer } from "../../components/Image-Container"
import { StaticImage } from "gatsby-plugin-image"
import { RapidBlueContainer } from "../../components/Rapid-Blue-Container"
import { CrisisInKenya } from "../../components/Crisis-In-Kenya"
import { Seo } from "../../components/Seo"

const Rapid = () => {
  return (
    <>
      <Seo
        title="RAPID - Relief Aid for People in Despair"
        description="MLIFE's flagship initiative providing unconditional small grants to families in need in Kenya."
      />{" "}
      {/* <Nav /> */}
      <RapidHero />
      <RapidPanels />
      <ImageContainer>
        <StaticImage
          src="../../images/programs/rapid/students-computer-lab.jpeg"
          placeholder="none"
          quality={100}
          alt="students smiling in a computer lab"
        />
      </ImageContainer>
      <RapidBio />
      <RapidBlueContainer />
      <CrisisInKenya />
      <ImageContainer>
        <StaticImage
          src="../../images/programs/rapid/behind-students-computer-lab.jpeg"
          placeholder="none"
          quality={100}
          alt="child looking to the side"
        />
      </ImageContainer>
      <BecomeOneOfUs />
      {/* <Footer /> */}
    </>
  )
}

export default Rapid
